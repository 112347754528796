import {Injectable, OnDestroy} from '@angular/core';
import {Subject, BehaviorSubject, fromEvent} from 'rxjs';
import {takeUntil, debounceTime} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserService} from "./user.service";

// Menu
export interface Menu {
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
    visible?:boolean;
    queryParams ?:{};
}

@Injectable({
    providedIn: 'root',
})
export class NavService implements OnDestroy {
itemsVisbilty = {
    'Dashboard':['Accounting','Management'],
    'Students':['Accounting','Management','Marketing'],
    'Courses':['Accounting','Management'],
    'Sales':['Accounting','Management'],
    'IT':['IT'],
    'Reports':['Accounting','Management']

};
    constructor(private router: Router ,private userService:UserService) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, 'resize')
            .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
            .subscribe((evt: any) => {
                this.setScreenWidth(evt.target.innerWidth);
                if (evt.target.innerWidth < 991) {
                    this.collapseSidebar = true;
                    this.megaMenu = false;
                    this.levelMenu = false;
                }
                if (evt.target.innerWidth < 1199) {
                    this.megaMenuColapse = true;
                }
            });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
        this.setItemsVisibility()
    }

    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search = false;

    // Language
    public language = false;

    // Mega Menu
    public megaMenu = false;
    public levelMenu = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth < 991 ? false : true;

    // Full screen
    public fullScreen = false;

    MENUITEMS: Menu[] = [
        {
            headTitle1: 'General',
        },
        {
            title: 'Dashboard',
            icon: 'home',
            type: 'link',
            path: '/dashboard',
            badgeType: 'light-primary',
            active: false,


        },
        {
            title: 'Students',
            icon: 'customers',
            type: 'sub',
            badgeType: 'light-primary',
            active: false,
            children: [
                {path: '/students/register', title: 'Student Register', type: 'link'},
                {path: '/students/list', title: 'Student List', type: 'link'},

            ],
        },
        {
            title: 'Courses',
            icon: 'learning',
            type: 'sub',
            badgeType: 'light-primary',
            active: false,
            children: [
                {path: '/course/register', title: 'Register Course', type: 'link'},
                {path: '/course/list', title: 'Course List', type: 'link'},
                {path: '/course/course-category', title: 'Course Categories', type: 'link'},
                {path: '/course/accreditation-entity', title: 'Accreditation Entities', type: 'link'},

            ],
        },
        {
            title: 'Sales',
            icon: 'widget',
            type: 'sub',
            badgeType: 'light-primary',
            active: false,
            children: [
                {path: '/sales/invoices-list', title: 'Invoices', type: 'link'},
                {path: '/sales/generate-invoice', title: 'Generate Invoice', type: 'link'},

                {path: '/sales/organization', title: 'Organizations', type: 'link'},
                {path: '/sales/quotation-list', title: 'Quotations', type: 'link'},
                {path: '/sales/generate-quotation', title: 'Generate Quotations', type: 'link'},
                {path: '/sales/invoices', title: 'underReviewInvoices', type: 'link' , queryParams: {'invoiceType' :'underReview'}},


            ],
        },
        {
            title: 'IT',
            icon: 'user',
            type: 'sub',
            badgeType: 'light-primary',
            active: false,
            children: [
                {path: '/user/register', title: 'Register User', type: 'link'},
            ],
        }, {
            title: 'Reports',
            icon: 'widget',
            type: 'sub',
            badgeType: 'light-primary',
            active: false,
            children: [
                {path: '/sales/sales-report', title: 'Sales Report', type: 'link'},
                {path: '/sales/sales-agent-report', title: 'Sales Agents', type: 'link'},
            ],
        }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }
    setItemsVisibility(){
        if (localStorage.getItem('userData')) {
            let userType = this.userService.getUserType();
            console.log('user type is ', userType)
            this.MENUITEMS.forEach(item => {
                item.visible = !!this.itemsVisbilty[item.title]?.includes(userType);

            })
        }
    }
}
