import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public  userDetails :any;
    private fetchCurrentUserDetails = environment.apiUrl + 'api/user/get';
    private createNewUserApi= environment.apiUrl + 'api/user/post';

    constructor(private httpClient:HttpClient ,private cookieService:CookieService , private  router:Router) {

    }
   public getCurrentUserDetails(){

            this.userDetails =  JSON.parse(localStorage.getItem('userData'))
        if (!this.userDetails){
            this.userDetails = {}
            this.httpClient.get<any>(this.fetchCurrentUserDetails+'?currentUser=true' , ).subscribe(
                response => {
                    this.userDetails = {username :response.username , groups : response.groups};
                    localStorage.setItem('userData', JSON.stringify(this.userDetails))
                    return this.userDetails;
                },
                error =>{
                  this.cookieService.delete('access' , '/');
                  localStorage.clear();
                    this.router.navigateByUrl('/auth/login')
                }
            )
        }
       return this.userDetails;

    }
    public registerSystemUser(body :any){

       return  this.httpClient.post(this.createNewUserApi,body, )
    }

    public getUserType(){
        return JSON.parse(localStorage.getItem('userData'))['groups'][0]['name'] ;
    }

}