import {HttpClient, HttpResponse} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {da, th} from "date-fns/locale";
import {environment} from "src/environments/environment";
import {CookieService} from 'ngx-cookie-service';
import * as path from "path";
import {UserService} from "../../shared/services/user.service";

declare var require
const Swal = require('sweetalert2')

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    public newUser = false;
    // public user: firebase.User;
    public loginForm: FormGroup;
    public show: boolean = false
    public errorMessage: any;

    constructor(private fb: FormBuilder, public router: Router, private http: HttpClient, private cookieService: CookieService , private userService : UserService) {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
    }

    ngOnInit() {
    }

    login() {
        let userDetails = {
            'email': this.loginForm.value['email'],
            'password': this.loginForm.value['password']
        };
        let loginurl = environment.apiUrl + 'api/token'
        this.http.post<any>(
            loginurl,
            userDetails
        ).subscribe(response => {
                if (response && response.access) {
                    this.cookieService.set('access', response.access ,{ path: '/' })
                    this.userService.getCurrentUserDetails().subscribe(
                        this.router.navigateByUrl('/dashboard')
                    )

                }

            }, error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Email or password are not correct.',
                });
            }
        )
    }

    showPassword() {
        this.show = !this.show
    }
    userDefaultRouting = {
        Marketing :'/students/register' ,
        IT : 'user/register',
        Management : 'Dashboard' ,
        Accounting :'Dashboard',
        Finance :'Dashboard',
        test:'students/register'
    }
}
