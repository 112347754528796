// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
        apiUrl: 'http://165.227.160.188:8000/',
// 165.227.160.188:8000
};

export const companyConfig = {
    company: 'ALBYAN INSTITUTE EDUCATION SUPPORT SERVICES L.L.C',
    arabicName: 'معهد البيان للخدمات التعليمية ذ.م.م',
    aliasName: 'KAYAN COLLEGE FOR INTERNATIONAL SECURITY AND LEGAL SCIENCES',
    email: 'info@albyaninstitute.org',
    phone: '+971 56 900 5544',
    address: 'Business Avenue - Port Saeed Road, Dubai, United Arab Emirates',
    trnNumber: '104084356500003',
    bankAccountName: 'Al-Bayan Institute Education Support Services',
    branchName: '',
    bankName: 'ADCB',
    bankAccountNumber: '12945800820001',
    bankIBAN: 'AE380030012945800820001',
    swiftCode: 'ADCBAEEE',
    currency: 'AED',

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
