<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form class="theme-form mega-form row g-3" [formGroup]="form" >
                        <h6 style="color: black">{{module}} Information</h6>
                        <div class="col-md-6 mb-3" *ngFor="let item of formItems">
                            <label class="col-form-label" style="color: black"> {{item.name}}
                                <span class="required" *ngIf="form.get(item.name).hasError('required')">*</span>
                            </label>


                            <input *ngIf="item.type != 'dropDownList'&& item.type!='file' && item.type !== 'multiDropDownList'" class="form-control" type={{item.type}}

                                   [formControlName]="item.name" placeholder="{{item.placeHolder}}"
                                   [(ngModel)]="item.value" [readonly] = "isReadOnly" (change)="vatManagement(item)">
                            <ng-select [items]="getDropDownList(item.name)" bindLabel="label" bindValue="value"
                                       class="js-example-basic-single"
                                       [formControlName]="item.name" groupBy="item.name"
                                       placeholder="{{item.placeHolder}}"
                                       *ngIf="item.type == 'dropDownList'"
                                      (open)="updateData($event)" [readonly] = "isReadOnly" (change)="onItemSelection($event)"></ng-select>
                          
                            <ng-select [items]="getDropDownList(item.name)" bindLabel="label" bindValue="value"
                                       class="js-example-basic-multiple"
                                       [formControlName]="item.name" groupBy="item.name"
                                       placeholder="{{item.placeHolder}}"
                                       [multiple]="true"
                                       *ngIf="item.type == 'multiDropDownList'"
                                       (open)="updateData($event)"></ng-select>

                                       
                            <input *ngIf="item.type=='file'" class="form-control" type={{item.type}}
                                   [formControlName]="item.name" placeholder="{{item.placeHolder}}"
                                   [(ngModel)]="item.value" [readonly] = "isReadOnly" (change)="onFileUpload($event ,item.name)">



                            <div *ngIf="form.get(item.name)!.invalid && form!.get(item.name).touched"
                                 class="validationerror">
                                provided value is not valid
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-primary me-1" type="submit" [disabled]="form!.invalid" *ngIf="!isReadOnly && module !='certificate'" (click)="onSubmit()">Submit
                            </button>
                            <button class="btn btn-primary me-1" *ngIf="isReadOnly" (click)="onReviewApproval(true)">Approve
                            </button>
                            <button class="btn btn-danger me-1" *ngIf="isReadOnly" (click)="onReviewApproval(false)">Reject
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>














